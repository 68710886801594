html,body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

a:link, a:visited, a:hover, a:active {
  text-decoration: none;
}


div {
  display: block;
}


.article-div {
  border-style: solid;
  border-color: black;
  color: black;
  text-align: center;

  width: 57vw;
  height: 37vh;
  overflow: hidden;
}

.slide {
  width: 100%;
  height: 100%;
}

.slider {
  width: 100%;
  heigth: 100%;
  display: flex;
  overflow-x: auto;
}
.slider::-webkit-scrollbar {    display: none;}


.nav-bar a {
  float: left;
  color: black;
  text-decoration: none;
  font-size: 1.2vw;
  width: 14.2vw;
  height: 3vw;
  text-align: center;
  background-color: white;
}
.nav-bar {
  top: 0;
  position: sticky;
  background-color: white;
  display: inline-block;
}

#slide-1 {
  background-color: #3d5ae3;
}
#slide-2 {
  background-color: #af66f1;
}
#slide-3 {
  background-color: #950095;
}
#slide-4 {
  background-color: #be447e;
}
#slide-5 {
  background-color: #ee2d2d;
}


.header {
  top: 0;
  background-color: white;
  text-align: center;
  font-family: Georgia;
}

.crop img {
  width:  17vw;
  height: 12vh;
  object-fit: scale-down;
  margin: -4vh 0 7vh 0;
}

.description {
  margin: -6vh 0 0 0;
}

#navButtons {
  width: 1.4vw;
}

.footer {
  background-color: white;
  display: table;
  height: 50px;
  width: 100%;
  text-align: center;
}
.footer p {
  color: black;
  vertical-align: middle;
}

@media screen and (min-width: 700px) {
  h2 {
    font-size: 2.5vw;
  }
}
@media screen and (max-width: 700px) {
  h2 {
    font-size: 4vw;
  }
}

@media screen and (min-width: 700px) {
  p {
    font-size: 1.2vw;
  }
}

@media screen and (max-width: 700px) {
  p {
    font-size: 3vw;
  }
}

